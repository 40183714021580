import React, { useState, useEffect } from "react";
import './Contact.css'
import svg_triangle from '../../picture/wave.svg'
import Resume from "../Resume/Resume Jovan Tatic.pdf"
const Contact = () => {
    let [contact, change_text_to_div] = useState('CONTACT'.split(''))

    const letter_move = (e) => {
        let letter = e.target
        letter.classList.remove("letter")
        void letter.offsetWidth
        void letter.offsetHeight
        letter.classList.add("letter")
    }

    const map_div = (letter) => {
        letter = letter == ' ' ? <div>&nbsp;</div> : letter
        return (<div className="letter contact_header" onMouseEnter={letter_move}>{letter}</div>)
    }
    function go_home() {
        window.location.href = '#home-page'
    }

    useEffect(() => {
        change_text_to_div(contact.map(map_div))
    }, [])

    return (
        <div id="page-contact">
            <img src={svg_triangle} alt="Triangle svg" draggable="false" id="triangle_1" />
            <img src={svg_triangle} alt="Triangle svg" draggable="false" id="triangle_2" />
            <div className="contact">
                <form action="http://taticjovan.com:5000/add" method='POST'>
                    <div className="center header_container">
                        <div className="header margin_50">{contact}</div>
                        <hr />
                    </div>
                    <div className="center margin_bottom_50 lets_connect">
                        Want to work together, let's connect
                    </div>
                    <div className="center contact_input">
                        <input type={'text'} placeholder={'Name'} title={'Please fill out this title'} name={'client-name'} maxLength={100} required/>
                        <input type={'email'} placeholder={'Enter e-mail'} title={'Please fill out this title'} name={'client-email'} maxLength={200} required/>
                        <textarea placeholder={"Your message"} name={'client-description'} maxLength={1024} required/>
                        <input type={'submit'} id="submit" value={'SUBMIT'} />
                    </div>
                </form>
                <footer>
                    <div className="footer_home">
                        <div className="footer_begin background_green" onClick={go_home}><i class="fa-solid fa-angles-up"></i></div>
                    </div>
                    <div className="footer_social">
                        <a href={Resume} download={"Resume Jovan Tatic.pdf"}><div title="Resume" className="footer_begin background_gray file"><i class="fa-solid fa-user"></i></div></a>
                        <a href="https://codepen.io/Jovan-Tati"><div title="Code Pen" className="footer_begin background_gray codepen"><i class="fa-brands fa-codepen"></i></div></a>
                        <a href="https://www.linkedin.com/in/jovan-tati%C4%87-054927265/"><div title="Linked in" className="footer_begin background_gray linkedin"><i class="fa-brands fa-linkedin-in"></i></div></a>
                    </div>
                    <div id="signature">
                        TATIC JOVAN&nbsp;
                        <span id="copy">
                            &copy;2023
                        </span>
                    </div>
                </footer>
            </div>
        </div>
    )
}

export default Contact