import React from 'react';
import Home from './Home/Home';
import Navigation from './Navigation/Navigation';
import About from './About/About';
import Contact from './Contact/Contact';
import Projects from './Projects/Projects';

function App() {
	return (
		<div>
			<section>
				<span id="home-page"></span>
				<Home />
			</section>
			<Navigation />
			<section>
				<span id="about-page"></span>
				<About />
			</section>
			<span id="contact-page"></span>
			<Projects />
			<Contact />
		</div>
	)
}

export default App;
