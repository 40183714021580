import React, { useState, useEffect } from "react";
import './About.css';
import Letter_Changer from "../Classes/Letter changer";
import profile_photo from '../../picture/profile.jpg';
const About = () => {
    let [about, change_text_to_div] = useState("ABOUT".split(''))
    let [about_pic, change_text_to_div_pic] = useState("About me".split(''))
    let [about_skill, change_text_to_div_skill] = useState("Skills".split(''))
    
    useEffect(() => {
        change_text_to_div(about.map(Letter_Changer.map_div))
        change_text_to_div_pic(about_pic.map(Letter_Changer.map_div))
        change_text_to_div_skill(about_skill.map(Letter_Changer.map_div))
    }, [])

    return (
        <div className="background">
            <div className="center header_container">
                <div className="header margin_50">{about}</div>
                <hr />
            </div>
            <div className="about_container">
                <div className="about inl_block">
                    <div className="about_picture">
                        <img alt="Picturo of Jovan Tatic" src={profile_photo} />
                    </div>
                </div>
                <div className="inl_block vert_align">
                    <div className="header about_text">{about_pic}</div>
                    <br />
                    <div className="paragraph_text about_text">I'm Jovan, a graduate student majoring in Software Engineering. With one year of work experience in the field, I'm a passionate learner and an open-minded individual who enjoys exploring new opportunities. I'm committed to approaching tasks with rigor and enthusiasm and recognize the critical role of technology in our world today.</div>
                </div>
            </div>
            <div>
                <div className="header center_header skill">{about_skill}</div>
                <div className="skill_container">
                    <div>
                        <i class="fa-brands fa-python python_logo"></i>
                        <div>Python</div>
                    </div>
                    <div>
                        <i class="fa-brands fa-square-js javascript_logo"></i>
                        <div>JavaScript</div>
                    </div>
                    <div>
                        <i class="fa-brands fa-react react_logo"></i>
                        <div>ReactJS</div>
                    </div>
                    <div>
                        <i class="fa-brands fa-html5 html_logo"></i>
                        <div>HTML</div>
                    </div>
                    <div>
                        <i class="fa-brands fa-css3-alt css_logo"></i>
                        <div>CSS</div>
                    </div>
                    <div>
                        <i class="fa-solid fa-c c_logo"></i>
                        <div>C</div>
                    </div>
                    <div>
                        <i class="fa-solid fa-database sql_logo"></i>
                        <div>SQL</div>
                    </div>
                    <div>
                        <i class="fa-regular fa-file-excel excel_logo"></i>
                        <div>Excel</div>
                    </div>
                    <div>
                        <i class="fa-brands fa-git-alt git_logo"></i>
                        <div>Git</div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default About