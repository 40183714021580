import React, { useState, useEffect } from "react";
import Image_Project from "./Image_Project/Image_Project";
import "./Projects.css"
import Letter_Changer from "../Classes/Letter changer";
import microstorm from "./microstorm.png"
import ktcy from "./ktcy.png"
import alarm_clock from "./alarm-clock.png"

const Projects = () => {
    let [projects,change_text_to_div] = useState("PROJECTS".split(''))

    useEffect(() => {
        change_text_to_div(projects.map(Letter_Changer.map_div))
    }, [])
    return (
        <div style={{backgroundColor:"rgb(245,245,245)"}} id="project-page">
            <div className="center header_container" style={{position:"relative",top:"50px"}}>
                <div className="header margin_50">{projects}</div>
                <hr />
            </div>
            <br/><br/><br/>
            <div className="paragraph_text flex_30">
                <Image_Project img={microstorm} alt_tag={"Microstorm logo"} link={"https://microstorm.com.cy/"} draggable={"false"}/>
                <Image_Project img={ktcy} alt_tag={"Knowledge & training logo"} link={"https://www.knowledgecy.com/"} draggable={false}/>
                <Image_Project img={alarm_clock} alt_tag={"Alarm clock logo"} link={"https://codepen.io/Jovan-Tati/pen/QWJLdNB"} draggable={"false"}/>
            </div>
        </div>
    )
}

export default Projects