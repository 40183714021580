import React from "react";
import './Navigation.css'

const Navigation = () => {

    const change_nav_color_on = (e) =>
    {
        let nav_element = e.target
        if(nav_element.style.color == "#23ce6b") return undefined
        nav_element.classList.toggle("link-nav-reverse")
        nav_element.classList.toggle("link-nav")
        
    }
    const change_nav_color_off = (e) =>
    {
        let nav_element = e.target
        nav_element.classList.toggle("link-nav")
        nav_element.classList.toggle("link-nav-reverse")
    }
    return (
        <div id="navigation_bar">
            <nav>
                <ul>
                    <li><a className="link-nav-reverse" onMouseEnter={change_nav_color_on} onMouseLeave={change_nav_color_off} href="#home-page">HOME</a></li>
                    <li><a className="link-nav-reverse" onMouseEnter={change_nav_color_on} onMouseLeave={change_nav_color_off} href="#about-page">ABOUT</a></li>
                    <li><a className="link-nav-reverse" onMouseEnter={change_nav_color_on} onMouseLeave={change_nav_color_off} href="#project-page">PROJECTS</a></li>
                    <li><a className="link-nav-reverse" onMouseEnter={change_nav_color_on} onMouseLeave={change_nav_color_off} href="#page-contact">CONTACT</a></li>
                </ul>
            </nav>
        </div>
    )
}
export default Navigation