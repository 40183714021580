class Letter_Changer
{
    static letter_move = (e) =>
    {
        let letter = e.target
        letter.classList.remove("letter")
        void letter.offsetHeight
        void letter.offsetHeight
        letter.classList.add("letter")
    }
    static map_div(letter) {
        letter = letter == ' ' ? <div>&nbsp;</div> : letter
        return (<div className="letter" onMouseEnter={Letter_Changer.letter_move}>{letter}</div>)
    }
}

export default Letter_Changer