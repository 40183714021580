import React from "react";
import "./Image_Project.css"

const Image_Project = (props) => {
    const resize_project = (e) => {
        const image = e.target
        const magic = image.closest("#magic-box")
        const view = magic.firstChild

        image.classList.remove("resize-project-not-hover")
        view.classList.remove("resize-view-not-hover")

        void image.offsetHeight
        void image.offsetHeight

        image.classList.add("resize-project-hover")
        view.classList.add("resize-view-hover")
    }
    const downsize_project = (e) => {
        const image = e.target
        const magic = image.closest("#magic-box")
        const view = magic.firstChild
        image.classList.remove("resize-project-hover")
        view.classList.remove("resize-view-hover")
        void image.offsetHeight
        void image.offsetHeight

        image.classList.add("resize-project-not-hover")
        view.classList.add("resize-view-not-hover")
    }
    return (
        <div id="magic-box" onMouseEnter={resize_project} onMouseLeave={downsize_project}>
            <div className="view-project">
                    <div>view</div>
                    <div>project</div>
                <a href={props.link}></a>
            </div>
            <img draggable={props.draggable} alt={props.alt_tag} src={props.img} />
        </div>
    )
}

export default Image_Project