import React, {useState,useEffect} from "react";
import CursorTrail from "cursor-trails";
import './Home.css'
import '../../global.css'

import trail_green from '../../picture/trail_green.png'
import trail_red from '../../picture/trail_red.png'
import trail_gray from '../../picture/trail_gray.png'

const Home = ()=>
{
    CursorTrail({
        container: document.querySelector('.trail'),
        images: [trail_green,trail_red,trail_gray],
        rate: 1,
        size: 5,
        life: 1,
        speed: 1.2
    });
    
    let [text_to_div,change_text_to_div] = useState("Hello, my name is Jovan Tatic.I'm a Software Engineer.".split(''))
    const letter_move = (e) =>
    {
        const letter = e.target
        letter.style.animationName = 'none'
        
        requestAnimationFrame(()=>
        {
            letter.style.animationName = ''
        })
    }
    const map_div = (letter)=>
    {
        
        letter = letter == ' ' ? <div>&nbsp;</div>:letter
        return(<div className="green_letter letter" onMouseEnter={letter_move}>{letter}</div>)
    }
    
    useEffect(()=>
    {
        change_text_to_div(text_to_div.map(map_div))
    },[])
    return (
        <div className="full_screen trail background_home">
            <div className="begin">
                {text_to_div}
            </div>
        </div>
    )
}
export default Home